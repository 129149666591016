import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import TypeToBlock from "../components/type-to-block";

export const query = graphql`
  query ($languageISO: String!, $language: String!, $slug: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    stripePlanMonthly: stripePlans(interval: { eq: "month" }) {
      id
      currency
      amount
      interval
      interval_count
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
    trainer: contentfulTrainerModule(
      slug: { eq: $slug }
      node_locale: { eq: $languageISO }
    ) {
      slug
      id
      metaTitle
      metaDescription
      trainerName
      modules {
        id
        heading
        name
        subheading
        type
        items {
          ... on ContentfulPlanModule {
            id
            planSlug
            planName
            shortDescription
            featureImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            featureImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          ... on ContentfulItems {
            body
            heading
            id
            link
            branchLink
            linkHelpText
            linkText
            subheading
            desktopImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            mobileImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            video
            content {
              raw
            }
          }
        }
      }
    }
  }
`;

const Trainer = ({
  data: {
    trainer: { metaTitle, metaDescription, modules },
    stripePlanMonthly,
    ribbon,
    header,
    footer,
  },
}) => {
  let hasBanner;
  const localePageAvailable = metaTitle ? true : false;
  const { t } = useTranslation();
  if (modules[0].type !== "Full page horizontal carousel") {
    hasBanner = "no-banner";
  }
  return (
    <>
      <Layout header={header} ribbon={ribbon} footer={footer} hasBanner={hasBanner}>
        <Seo title={metaTitle ? metaTitle : "Fitplan®"} metaDescription={metaDescription} />
        {(localePageAvailable && modules) &&
          modules.map((module, key) => (
            <TypeToBlock
              key={`module-${key}`}
              stripePlanMonthly={stripePlanMonthly}
              module={module}
            />
          ))}
        {!localePageAvailable && <section className="error_page_section">
          <div className="container">
            <h4>{t("contentMissing")}</h4>
            <h5>{t("contentMissingMessage")}</h5>
          </div>
        </section>}
      </Layout>
    </>
  );
};

export default Trainer;
